/* 
Get all users
Get all quizzes for month
GET Each users quiz answers (lazyload?)
*/
import React, { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';
// import Table from 'react-bootstrap/Table';
import api from '../../../utils/api';
import { Button } from "react-bootstrap";

import { USER_TYPE } from '../../../utils/constants';


import cookies from "../../../utils/cookies";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useCallback } from 'react'
import { toast } from 'react-toastify';


export default function Index() {
  const [selectedUserType, setSelectedUserType] = useState('USER');

  const handleViewUserType = (e) => {
    const value = e.target.value;
    setSelectedUserType(value);
  };

  const [totalTableElements, setTotalTableElements] = useState(0);
  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const weeklyAnalyticsCall = useCallback(async () => {
    try {

      const weeklyAnalyticsRes = await api.findAllByUserType(perPage, 0, selectedUserType);
      console.log(weeklyAnalyticsRes);
    } catch (e) {
      console.error(e);
    }
  }, [selectedUserType]);
  useEffect(() => {
    weeklyAnalyticsCall();
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi.showLoadingOverlay();
          const page = params.endRow / perPage;


          api.findAllByUserType(perPage, page - 1, selectedUserType)
            .then(resp => resp?.data)
            .then(res => {
              gridApi.hideOverlay();


              const initUsers = res.content.filter((x) => x.firstName);

              console.log(initUsers);
              setTotalTableElements(res.totalElements);
              params.successCallback(initUsers, res.totalElements);
            }).catch(err => {
              gridApi.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });


        }
      }
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, weeklyAnalyticsCall, selectedUserType]);



  // useEffect(() => {

  //   const getAndSetAllUsers = async () => {
  //     try {
  //       const rawUsersRes = await api.findAllByUserType(100, 0, selectedUserType);
  //       const initUsers = rawUsersRes.data.content;
  //       setUsers(initUsers);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   };
  //   getAndSetAllUsers();
  // }, [selectedUserType]);
  const dateFormatter = ({ value }) => {
    if (!value) {
      return;
    }
    let d = new Date(value).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
    return d;
  }
  const actionsFormatterA = ({ value, data }) => {

    return <>

      <Button
        variant="outline-dark"
        size="sm"
        target='_blank'
        href={`../admin/TradeViewer/${data?.id}`}
      >
        Trade
      </Button>
      &nbsp;&nbsp;
      <Button
        variant="outline-success"
        size="sm"
        href={`../chatViewer?room=${data?.id}&username=${data?.username}&firstName=${data?.firstName}&lastName=${data?.lastName}`}
      >
        Chat
      </Button>
      &nbsp;&nbsp;
      <Button
        variant="outline-primary"
        size="sm"
        href={`./userViewer?username=${data?.username}`}
      >
        Profile
      </Button>
      &nbsp;&nbsp;
      <Button
        variant="info"
        size="sm"
        href={`./TimelineViewer?username=${data?.id}`}

      >
        Timeline
      </Button>

      &nbsp;&nbsp;
      <Button
        variant="primary"
        size="sm"
        onClick={() => enableDisableUser(data)}
      >
        {data?.enabled ? 'Disable' : 'Enable'}
      </Button>
    </>;
  }
  const showToast = async (msg) => {
    try {
      toast.dismiss();
      toast.info(msg, {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (error) {
      console.log('error', error);
    }

  }
  const enableDisableUser = (user) => {


    try {
      let data = {
        userID: user?.id,
        flag: !user?.enabled
      }

      api.enableDisableUser(data).then((res) => {
        if (res) {
          showToast(`User ${user?.enabled ? 'Disabled' : 'Enabled'} Successfully`);
          setTimeout(() => {
            window.location.reload();
          }, 3000);

        }
      });



    } catch (e) {
      console.error(e);
    }
  };
  const downloadReport = async () => {
    try {
      const rawUsersRes = await api.downloadReportByUserType(null, selectedUserType);
      if (rawUsersRes?.data) {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = 'data:text/plain;base64,' + window.btoa(unescape(encodeURIComponent(rawUsersRes?.data)));
        a.download = "export.csv";
        a.click();
      }
    } catch (e) {
      console.error(e);
    }
  };
  const downloadServicerReport = async () => {
    try {
      const rawUsersRes = await api.downloadServicerReport(null, selectedUserType);
      if (rawUsersRes?.data) {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = 'data:text/plain;base64,' + window.btoa(unescape(encodeURIComponent(rawUsersRes?.data)));
        a.download = "export.xlsx";
        a.click();
      }
    } catch (e) {
      console.error(e);
    }
  };



  return (

    <>

      {/* <div id="ciftable">
        <div>
          <h1>{`Customers`} | Total: {totalTableElements}</h1>


          <Button variant="secondary" onClick={downloadReport}>Download Report</Button>
        </div>
        <hr />


        <h2>Select User Type to view</h2>
        <div>
          <Form.Select
            id="userType"
            className="mr-8"
            aria-label="User Type to view"
            onChange={handleViewUserType}
            value={selectedUserType}
          >
            {Object.values(USER_TYPE)?.map((i) => (
              <option key={`userType-${i}`} value={i}>
                {i === 'USER' ? 'CUSTOMER' : i}
              </option>
            ))}
          </Form.Select>
        </div>

        {users ? (<DatatableWrapper
          body={users}
          headers={STORY_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 5,
              options: [5, 10, 15, 20]
            }
          }}
        >
          <Row className="mb-4 p-2">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Pagination />
            </Col>
          </Row>
          <Table>
            <TableHeader />
            <TableBody />
          </Table>
        </DatatableWrapper>) : (<p>Fetching User(s) List...</p>)}



      </div> */}


      <div id="applicanttable">



        <div>
          <h1>{`Customers`} | Total: {totalTableElements}</h1>



          <Button variant="secondary" onClick={downloadReport}>Download Report</Button>
          &nbsp;
          &nbsp;

          <Button variant="primary" onClick={downloadServicerReport}>Download Servicer Report</Button>
        </div>
        <hr />
        <h2>Select User Type to view</h2>
        <div>
          <Form.Select
            id="userType"
            className="mr-8"
            aria-label="User Type to view"
            onChange={handleViewUserType}
            value={selectedUserType}
          >
            {Object.values(USER_TYPE)?.map((i) => (
              <option key={`userType-${i}`} value={i}>
                {i === 'USER' ? 'CUSTOMER' : i}
              </option>
            ))}
          </Form.Select>
        </div>
        <hr />
        <div className="App">

          <div className='container d-flex flex-column flex-grow-1 py-4'>
            <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
              <div style={{ flexGrow: '1' }}>


                <div className="ag-theme-alpine ag-style" style={{ height: '100%', width: '100%' }}>
                  <AgGridReact
                    pagination={true}
                    rowModelType={'infinite'}
                    paginationPageSize={perPage}
                    cacheBlockSize={perPage}
                    onGridReady={onGridReady}
                    rowHeight={60}
                    sortable={true}
                    unSortIcon={true}
                    defaultColDef={{ flex: 1, sortable: true, unSortIcon: true, sortingOrder: ['ASC', 'DESC'] }}
                    overlayLoadingTemplate={
                      '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
                    }
                    overlayNoRowsTemplate={
                      '<span className="ag-overlay-loading-center">No data found to display.</span>'
                    }
                  >
                    <AgGridColumn minWidth={150} field="username" headerName="User Name" cellClass="vertical-middle" />

                    <AgGridColumn minWidth={150} field="firstName" headerName="First Name" cellClass="vertical-middle" />
                    <AgGridColumn minWidth={150} field="lastName" headerName="Last Name" cellClass="vertical-middle" />
                    {/* <AgGridColumn  field="lastName" headerName="Last Name" cellClass="vertical-middle" /> */}

                    <AgGridColumn minWidth={150} field="loanStartDate" headerName="Loan Start Date" cellRendererFramework={dateFormatter} cellClass="vertical-middle" />

                    <AgGridColumn minWidth={150} field="createDateTime" headerName="Created" cellRendererFramework={dateFormatter} cellClass="vertical-middle" />
                    <AgGridColumn minWidth={350} field="applicantId" headerName="Actions" cellRendererFramework={actionsFormatterA} cellClass="vertical-middle" />

                  </AgGridReact>
                </div>



              </div></div></div>

        </div>



      </div>
    </>

  );
}
